.LanguageSwitcher{

  .selected-language{
    width:2rem;
    height:auto;
    border: none;
  }

  svg{
    display: none;
  }
  
  .language-option span{
    display: none;
  }

  .MuiSelect-select{
    img{
      width:2rem;
      height:auto;
      border:none !important;

    }
    padding:0 !important;
  }
  fieldset {
    border: none;
  }

}
.language-option{
  display: flex;
  align-items: center;

  img{
    width:2rem;
    height:auto;

  }
  span{
    padding:0 1.5rem;
  }
}