$colorPrimary: #0068a5;
$colorSecondary: #179fdb;
$colorAccent: #004e82;
$colorError: #f53c56;
$colorSuccess: #f53c56;
$colorText: #212529;
$colorDisabled: #838383;

$lightBackground: #f7fafc;

$breakpoint-laptop: 1440px;
* {
  box-sizing: border-box;
}
:root {
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
      'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
      'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;
}

html,
body {
  margin: 0;
  padding: 0;
}

body {
  font-family: Helvetica, Arial, sans-serif;
  background: url(../img/background.png) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

p {
  margin: 0;
}

.wrapper {
  margin-bottom: 3em;
  max-width: 1400px;
  margin: 0 auto;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;

  > * {
    width: 33.33%;
  }
}

.status {
  position: relative;
  top: 20px;
  color: #fff;
  text-align: right;

  button {
    display: inline-block !important;
  }

  a {
    color: #fff;

    &:hover {
      color: #fff;
    }

    span {
      margin-left: 5px;
    }
  }
}

h3 {
  color: $colorAccent;
  margin-bottom: 20px !important;
}

.px-splitter-logo-v2 {
  width: 261px;
  height: 75px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
}

#app {
}

.content {
  width: 900px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px;
  border-radius: 0.3rem;
  box-shadow: 0 3px 17px 0 rgba(44, 40, 40, 0.11);
  background-color: white;
  color: $colorText;
  padding: 30px 45px 0px 50px;
}

div[data-test="authenticator-error"] + span {
  color: $colorError;
}

input.error {
  border-color: $colorError;
}

button[name="login"] {
  height: 40px;
  margin: 20px 0;
  padding: 5px 10px;
  text-align: center;
  border-radius: 4px;
  background-color: $colorSecondary;
  border-color: transparent;
  color: white;
}

button[name="logout"] {
  display: block;
  background-color: transparent;
  border: none;
  color: white;
  text-decoration: underline;
  outline: none;
  padding: 0;
  margin: 0;
}

table {
  text-align: left;
  padding-left: 50px;
  padding-right: 50px;
  background-color: white;

  .small-col {
    width: 180px;
  }

  .wide-col {
    width: 50%;
  }
}

thead {
  color: #8898aa;

}

select {
  background-color: $lightBackground;
}

.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr:nth-child(odd) > th {
  background-color: $lightBackground;
}

.btn-style {
  color: white;
  border-radius: 4px;
  padding: 6px 15px;
  opacity: 1;
  transition: opacity 0.3s;

  &:hover {
    opacity: 0.75;
  }
}

.add-btn,
.add-btn:focus {
  border: solid 1px $colorAccent;
  background-color: $colorAccent;
}

.save-btn {
  border: solid 1px $colorAccent;
  background-color: $colorAccent;
}

.add-split-btn {
  color: white;
  height: 40px;
  margin: 0 auto 50px auto;
  border-radius: 4px;
  border: solid 1px $colorAccent;
  background-color: $colorAccent;
}

.btn-with-icon {
  border: none;
  background-color: transparent;
  transition: opacity 0.3s;
  margin-right: 5px;
  color: $colorPrimary;

  &:hover {
    opacity: 0.75;
  }

  &:focus {
    outline: none;
  }
}

.btn-in-form {
  position: relative;
  top: 5px;
}

.btn-text-with-icon {
  background: transparent;
  border: none;
  text-align: left;
  outline: none !important;

  &:focus {
    outline: none !important;
  }

  * {
    display: inline-block;
  }

  svg {
    margin-right: 1em;
  }
}

.btn-back {
  color: $colorAccent;

  svg {
    position: relative;
    top: -3px;
  }
}

.btn-disabled {
  background-color: $colorDisabled;
  border-color: $colorDisabled;
}

input[data-change-type="statusCode"],
input[data-change-type="url"],
input[data-change-type="weight"],
#name {
  background-color: #f7fafc;
}

.form-label {
  padding: 6px 0 0;
  margin: 10px 0 0;

  &__empty {
    width: 100%;
    height: 1.4em;
  }
}

.split-link {
  color: $colorText;

  &:hover {
    color: $colorText;
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  text-align: center;
  line-height: 100vh;
  color: #fff;
  z-index: 99999;
}

.done {
  text-decoration: line-through;
}

.notifications-component {
  top: 0;
  left: 0;
}

.domain-select {
  display: inline-block;
  list-style: none;
  text-align: left;
  margin: 0 0 20px 0;
  padding: 0;

  li {
    padding: 10px 0;

    &:not(:last-of-type) {
      border-bottom: 1px solid #ccc;
    }

    &:hover {
      cursor: pointer;
    }

    &.active {
      font-weight: 600;
      text-indent: 10px;
    }
  }
}

.size {
  height: 10px;
  width: 10px;
  margin-left: 5px;
}

.modal-footer {
  border-top: none;
}

.footer-controls {
  margin-bottom: 20px;
}

.light-text {
  color: #999;
  font-style: italic;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $lightBackground;
  height: 3em;
  line-height: 3em;
  border-top: 1px solid $colorDisabled;

  &__inner {
    width: 900px;
    margin: 0 auto;
    padding: 0 10px;
  }

  a {
    color: $colorText;
  }
}

.main {
  position: relative;
  overflow-x: hidden;
  padding-right: 5px;
}

.main-slides {
  position: relative;
  width: 200%;
  transform: translateX(0);
  display: flex;
  transition: transform 0.3s ease-in-out;

  &--right {
    transform: translateX(-50%);
  }
  
  &__slide {
    width: 50%;
  }
}

@media (max-width: 1450px) {
  #mainHeader {
    width: 95%;
  }

  #mainBody {
    // width: 95%;
  }
}

@media (min-width: $breakpoint-laptop) {
  #app,
  input,
  table,
  .header {
    // width: 80%;
  }
}

@media (max-width: 900px) {
  body {
    font-size: 0.8rem !important;
  }

  .header {
    width: 100%;
  }

  .wrapper {
    padding: 0 5px;
  }

  .space {
    display: none;
  }

  .content {
    width: 100%;
    margin-top: 10px;
    padding: 20px 10px;
  }

  #app {
    width: 100%;
    padding: 0 10px 0px 10px;
  }

  .px-splitter-logo-v2 {
    width: 200px;
    height: auto;
    margin: 15px 10px 10px;
  }

  .status {
    left: auto;
    right: 10px;
    top: 10px;
  }

  h3 {
    font-size: 1.2rem !important;
    line-height: 1.2rem !important;
  }
}
