
 
    #mainHeader{
        flex-direction:column;
        gap:1rem;
    }
    


@media (min-width:800px) {

    #mainHeader{
        flex-direction:row;
        gap:0;
    }   
    .header-section{
        width: 33%;
    }
}