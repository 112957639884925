.splitLink-column{
    display: inline-flex;
    flex-direction: column;
    max-width: 100%;
}
.description{
        width:100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        -webkit-line-clamp: 1;
}

.pagination-wrapper{
    justify-content:flex-end;
}

@media (max-width: 800px) {
    th.desktop-content,
    td.desktop-content{
        display: none;
    }
    
    .pagination-wrapper{
        justify-content:center;
    }
}